// useLogVisitor.js
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useLogVisitor = () => {
  const router = useRouter();

  useEffect(() => {
    const visitor = Array.isArray(router.query.visitor) ? router.query.visitor[0] : router.query.visitor;

    if (visitor) {
      fetch(`/.netlify/functions/logVisitor?visitor=${encodeURIComponent(visitor)}`)
        .then(response => response.json())
        .then(data => {
          console.log('Visitor logged:', data.message);
        })
        .catch(error => {
          console.error('Error logging visitor:', error);
        });
    }
  }, [router.query.visitor]);
};

export default useLogVisitor;